(function ($, Drupal) {
  'use strict';

  /**
   * Main Menu Formatter v1
   */
  var mainMenuFormatterV1 = {
    formatter: $('#site-navigation')
  };

  /* Keyboard Events for Menu Dropdown and Carousel - Start*/
  $('.js-is_dropdown').on('focusin', function () {
    $(this).trigger('mouseenter');
  });

  $('.js-is_carousel').on('focusin', function () {
    var $thisCarousel = $(this).parents('.is-carousel');
    var $lastChild = $thisCarousel.children(':last');

    $lastChild.find('.js-carousel_controls').on('focusout', function () {
      $thisCarousel.trigger('mouseleave');
    });
  });
  /* Keyboard Events for Menu Dropdown and Carousel - End*/

  /* Keyboard Events for SubMenus - Start*/
  $('.js-mm_subnav_item').on('focusin', function () {
    var $this = $(this);

    $this.siblings().removeClass('is-active');
    if ($this.hasClass('is-submenu')) {
      $this.addClass('is-active');
      $this.find('.js-subMenu-link').attr('aria-expanded', 'true');
    }
    if ($this.next().length === 0) {
      $this.on('focusout', function () {
        var $mainMenu = $this.parents('.js-nav_dropdown');

        $mainMenu.attr('aria-expanded', 'false').trigger('mouseleave');
        $mainMenu.next(':focusable').focus();
      });
    }
  });

  $('.js-mm_subnav_item').each(function () {
    if ($(this).hasClass('is-submenu')) {
      $(this).find('.js-subMenu-link').attr({
        'aria-expanded': 'false',
        'aria-haspopup': 'true'
      });
    }
  });
  $('.js-my_mac_link').focus(function () {
    $(this).parent().trigger('mouseenter');
    $(this).find('.js-my-mac-overlay').focus();
  });

  /* Keyboard Events for SubMenus - End*/

  Drupal.behaviors.mainMenuFormatterV1 = {
    get: function (key) {
      return mainMenuFormatterV1[key];
    },

    set: function (key, val) {
      mainMenuFormatterV1[key] = val;
    },

    buildMobileNav: function ($module, context) {
      var $mobileNav = $('#mobile-navigation');

      if (!$mobileNav.hasClass('mobileNav-processed')) {
        $mobileNav.after('<div class="mobile-nav__overlay"></div>');
        var mobileNavOverlay = $('.mobile-nav__overlay');

        // Add menu-sub attribute and format menu link text.
        $mobileNav.find('.menu').first().attr('menu-sub', '0');
        $mobileNav.find('.menu-item-container').each(function (i) {
          var item = i + 1;
          var text = $(this)
            .contents()
            .filter(function () {
              return this.nodeType === 3;
            });

          $(this).attr('menu-sub', item);
          text.wrap('<div class="menu--title" role="button"></div>');
          if ($(this).hasClass('expanded')) {
            $(this)
              .attr('aria-expanded', 'false')
              .find('.menu-container')
              .first()
              .attr('aria-hidden', 'true');
          }
        });

        // Find page & parent cat to contextualize nav state
        var currentPage;
        var ancestorDepthIndices = []; // "menu-sub" item value

        if (page_data?.['consolidated-categories']?.categories) {
          var categories = page_data['consolidated-categories'].categories;

          if (categories.length >= 1) {
            currentPage = {
              url: categories[0].url,
              catId: categories[0].BREADCRUMB_MENU_CAT_ID
                ? 'CAT' + categories[0].BREADCRUMB_MENU_CAT_ID
                : categories[0].CATEGORY_ID,
              parentCatId: categories[0].PARENT_CAT_ID,
              breadcrumbCatId: categories[0].BREADCRUMB_MENU_CAT_ID
                ? categories[0].BREADCRUMB_MENU_CAT_ID
                : categories[0].PARENT_CAT_ID
            };
          }
        }
        if (currentPage && currentPage.catId) {
          var catid = currentPage.catId.split('CAT');

          catid = catid[1];
          // Category link that matches current page
          var activeTrailNodes = $mobileNav.find("a[href*='/products/" + catid + "/']");

          activeTrailNodes.each(function (i) {
            var ancestorMenuItems;

            // First instance of the active menu: contains the depth/tree of all ancestor menus
            if (i == 0) {
              ancestorMenuItems = $(this).parentsUntil(
                $('div.field-menu'),
                'li.menu-item-container'
              );
              ancestorMenuItems.each(function (i) {
                // Stores indices of ancestor items in nav heirarchy
                ancestorDepthIndices.push($(this).attr('menu-sub'));
              });
              ancestorMenuItems = $(this).closest('li.menu-item-container');
              if (typeof ancestorMenuItems[0] != 'undefined') {
                ancestorMenuItems[0].id = 'js-mobile-nav-submenu-' + currentPage.breadcrumbCatId;
              }
            }
          });

          // Secondary nav link (mobile breadcrumb on product pages)
          $('#js-sec-nav-link-' + currentPage.breadcrumbCatId).click(function (e) {
            e.preventDefault();
            if (!$('#mobile-nav').is(':visible')) {
              return;
            }
            var parentCatId = $(this).attr('data-catid');

            if (!parentCatId || parentCatId === '') {
              return;
            }
            $.sidr('open', 'mobile-navigation');
            var submenu = $('#js-mobile-nav-submenu-' + parentCatId);

            if (submenu.length) {
              $('#mobile-navigation ul.menu[menu-sub]').each(function () {
                $(this).hide();
              });
              submenu.addClass('external-trigger');
              submenu.trigger('click');
            }
          });
        }

        var headerHeight = $('.site-header__fixed-wrapper').height();
        var bodyPadding = $('body').css('padding-top');
        var mobileNavToggle = $('#mobile-nav');
        var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
        var siteContainer = $('.site-header').siblings('.site-container');
        var bannerHeight;
        var topOffset;
        var navSide = site.direction.isRTL ? 'right' : 'left';
        var externalTrigger;

        mobileNavToggle.sidr({
          displace: false,
          name: 'mobile-navigation',
          renaming: false,
          source: '#mobile-navigation',
          side: navSide,

          onOpen: function () {
            $('body, html').addClass('noscroll fixedpos sidr-open');
            $('.site-header__fixed-wrapper').addClass('header-pos');
            bodyPadding = $('body').css('padding-top');
            dimOverlay.prependTo(siteContainer);

            if ($('.header-search__icon').hasClass('search-active')) {
              $('.header-search__icon').trigger('click');
            }
            bannerHeight = $('html').hasClass('site-banner__is-open')
              ? $('.site-banner').height()
              : 0;
            topOffset = headerHeight + bannerHeight;
            $mobileNav.css({
              top: topOffset + 'px',
              height: 'calc(100% - ' + topOffset + 'px)',
              'margin-top': bodyPadding
            });

            // Listen for banner being closed
            $(window).on('sidewideBanner:up', function () {
              // Recalculate bannerheight with sitewideBanner closed
              bannerHeight = $('html').hasClass('site-banner__is-open')
                ? $('.site-banner').height()
                : 0;
              topOffset = headerHeight + bannerHeight;
              $mobileNav.css({
                top: topOffset + 'px',
                height: 'calc(100% - ' + topOffset + 'px)',
                'margin-top': bodyPadding
              });
            });

            var mainMenu = $mobileNav.find('.menu[menu-sub="0"]');
            var scrollableCont = $mobileNav;
            var fieldMenu = scrollableCont.find('.field-menu');
            var prevMenu = [];
            var scrollTopPos = 0;

            // Default to top level
            mainMenu.once().addClass('is-current');

            $mobileNav
              .find('.menu-item-container')
              .once()
              .click(function (e) {
                var parentMenu = $(this).parents('.menu-item-container');
                var item = $(this).attr('menu-sub');
                var last = prevMenu[prevMenu.length - 1];
                var $thisMenu = $(this);
                var isWhatsNew = $thisMenu.hasClass('mm-whats-new-items-mobile-menu');
                var $newItemsMenu = $mobileNav.find('.mm-whats-new-items-mobile-menu');
                // Different animation type and speed depending on the menu (WHATS NEW is very tall)
                var animationType = isWhatsNew ? 'easeInOutQuad' : 'easeInQuart';
                var animationSpeed = isWhatsNew ? 900 : 300;
                var $activateItem = $();

                externalTrigger = false;
                // External trigger (e.g. secondary nav): simulate the depth/history
                if (currentPage?.parentCatId && $(e.target).hasClass('external-trigger')) {
                  $(e.target).removeClass('external-trigger');
                  externalTrigger = true;
                }

                if (!$(e.target).is('a')) {
                  // Selected sub-menu is not active so expand it
                  if (!$thisMenu.hasClass('is-active')) {
                    // Close all expanded menus except parent containers
                    if (externalTrigger) {
                      $thisMenu
                        .add('.menu-item-container')
                        .not(parentMenu)
                        .removeClass('is-current is-active')
                        .attr('aria-expanded', 'false')
                        .find('.menu-container')
                        .attr('aria-hidden', 'true')
                        .hide();
                    } else {
                      $thisMenu
                        .add('.menu-item-container')
                        .not(parentMenu)
                        .removeClass('is-current is-active')
                        .attr('aria-expanded', 'false')
                        .find('.menu-container')
                        .attr('aria-hidden', 'true')
                        .slideUp(animationSpeed, animationType);
                    }

                    $thisMenu.addClass('is-active is-current').attr('aria-expanded', 'true');

                    // If externaltrigger, show target depth & ancestors
                    if (externalTrigger) {
                      mainMenu.show();
                      for (let i = ancestorDepthIndices.length - 1; i >= 0; --i) {
                        $activateItem = mainMenu.find(
                          '.menu-item-container[menu-sub="' + ancestorDepthIndices[i] + '"]'
                        );

                        $activateItem
                          .find('.menu-container')
                          .first()
                          .attr('aria-hidden', 'false')
                          .css('display', 'block');
                        $activateItem.addClass('is-active');
                      }
                      prevMenu = ancestorDepthIndices.slice(0);
                      prevMenu.reverse();
                      prevMenu.pop();
                    } else {
                      // Identify current menu and expand
                      $thisMenu
                        .find('.menu-container')
                        .first()
                        .attr('aria-hidden', 'false')
                        .slideDown(animationSpeed, animationType);
                    }

                    // Add height to ensure container is scrollable
                    fieldMenu.css('height', '100%');
                    if (isWhatsNew) {
                      fieldMenu.css('height', $newItemsMenu.height() * 2.5);
                    } else {
                      fieldMenu.css('height', $mobileNav.height() * 2.5);
                    }
                    // Animate once animation completed
                    $mobileNav
                      .find('.menu-container')
                      .promise()
                      .done(function () {
                        // Get current menu top position
                        if (isWhatsNew) {
                          scrollTopPos = 0;
                        } else {
                          scrollTopPos =
                            $(
                              '.menu-item-container[menu-sub="' + item + '"] .menu--title'
                            )?.position()?.top || 0;
                        }
                        // Position scrollable area
                        if (externalTrigger) {
                          scrollableCont[0].scrollTop = scrollTopPos;
                        } else {
                          scrollableCont.animate(
                            {
                              scrollTop: scrollTopPos
                            },
                            300
                          );
                        }
                      });

                    parentMenu.add(mainMenu).removeClass('is-current');
                    // If we are at the top level
                    if (parentMenu.length === 0) {
                      prevMenu = [];
                      last = 0;
                    } else if (!externalTrigger) {
                      prevMenu.push(parentMenu.attr('menu-sub'));
                    }

                    // Selected sub-menu is active, but menu is hidden
                  } else if (externalTrigger) {
                    mainMenu.show();
                    scrollableCont[0].scrollTop = scrollTopPos;

                    // Selected sub-menu is already active & menu is visible
                  } else {
                    // Close all expanded menus except parent containers
                    $thisMenu
                      .add('.menu-item-container')
                      .not(parentMenu)
                      .removeClass('is-current is-active')
                      .attr('aria-expanded', 'false')
                      .find('.menu-container')
                      .attr('aria-hidden', 'true')
                      .slideUp(animationSpeed, animationType);

                    // If we are at the top level
                    if (parentMenu.length === 0) {
                      prevMenu = [];
                      last = 0;
                    }

                    if (last > 0) {
                      fieldMenu.css('height', '120%');
                      $mobileNav
                        .find('.menu-item-container[menu-sub="' + last + '"]')
                        .addClass('is-active is-current')
                        .attr('aria-expanded', 'true');
                      // Add height to ensure container is scrollable
                      fieldMenu.css('height', $mobileNav.height() * 2.5);
                      scrollableCont.animate(
                        {
                          scrollTop: $(
                            '.menu-item-container[menu-sub="' + last + '"] .menu--title'
                          ).position().top
                        },
                        300
                      );

                      prevMenu.pop();
                    } else {
                      $mobileNav
                        .find('.menu-item-container')
                        .removeClass('is-current is-active')
                        .attr('aria-expanded', 'false');
                      mainMenu.addClass('is-current is-active');
                      fieldMenu.css('height', '100%');
                    }
                  }

                  return false;
                }
              });

            mobileNavOverlay.once().click(function () {
              $.sidr('close', 'mobile-navigation');

              return false;
            });

            $(window).on('orientationchange', function () {
              $.sidr('close', 'mobile-navigation');
            });
          },
          onClose: function () {
            $('body, html').removeClass('noscroll fixedpos sidr-open');
            $('.site-header__fixed-wrapper').removeClass('header-pos');
            dimOverlay.remove();
            // Hack for resetting body padding from admin toolbar
            if ($.cookie('client.isMobile') === 0 && bodyPadding !== '0px') {
              setTimeout(function () {
                $('body').css('padding-top', bodyPadding);
              }, 300);
            }
          }
        });

        // Rewrite What's New menu with content blocks from items node: node/1968

        var $whatsNewMenuContent = $mobileNav.find('.site-navigation > .block-nodeblock');
        var $whatsNewArray = [
          {
            menuName: "What's New", // just for human readability
            menuSubID: 1
          }
        ];

        $($whatsNewArray).each(function (index) {
          var $whatsNewMenu = $mobileNav.find(
            '.menu-item-container[menu-sub="' + $whatsNewArray[index].menuSubID + '"]'
          );
          // Index +1 is to skip main menu
          var $whatsNewItems = $($whatsNewMenuContent[index + 1])
            .find('.content')
            .first();

          if ($whatsNewItems.length > 0) {
            $whatsNewItems.addClass('mm-whats-new-items-mobile-contents');
            $whatsNewMenu.addClass('mm-whats-new-items-mobile-menu');
            $whatsNewMenu.find('.menu-container').empty().append($whatsNewItems);
            $whatsNewMenu
              .find('.mm-whats-new-items-mobile-contents')
              .wrap('<ul class="menu"><li class="menu-item-container" menu-sub="999"></li></ul>');
          }
        });
      }
      $mobileNav.addClass('mobileNav-processed');
    },

    buildMoreMenu: function ($module, menu, moreMenu, moreMenuMenu) {
      // Start by resetting everything
      moreMenuMenu.empty();
      var menuItems = $('li.site-navigation__item:not(.subnav):not(.site-navigation__more)', menu);
      var $itemsToFit = $('.fit-main-menu', menu);
      var mainMenuWidth;

      // Determine how much width we have
      moreMenu.show();
      var widthMoreMenu = parseInt(moreMenu.outerWidth(true));

      moreMenu.hide();
      var availableWidth = parseInt(menu.parent().outerWidth(true)) - widthMoreMenu;
      var menuWidthSoFar = 0;

      // For each menu element
      menuItems.each(function () {
        var item = $(this);

        // Calculate the width of the menu so far
        item.show();

        // For the last element, use only the inner width
        if (item[0] == menuItems.last()[0]) {
          menuWidthSoFar += item.width();
        } else {
          menuWidthSoFar += item.outerWidth(true);
        }

        // If the menu overflows, move the item to the More link
        if (menuWidthSoFar > availableWidth && !item.hasClass('site-navigation__more')) {
          item.clone(true).css('display', 'block').appendTo(moreMenuMenu);
          item.hide();
        }
      });

      if ($.trim(moreMenuMenu.html()).length) {
        moreMenu.show();
      } else {
        moreMenu.hide();
      }

      mainMenuWidth = menu.closest('.main-menu').width();
      $itemsToFit.each(function () {
        $(this).find('.site-navigation__menu').width(mainMenuWidth);
      });
    },

    initDropdowns: function ($module) {
      $module
        .find('.is-dropdown')
        .not('.site-navigation__more')
        .each(function (i) {
          var $this = $(this);
          var dropdownMenuItem = $this.find('ul.site-navigation__menu');

          $this.attr('menu-sub', i);
          if (dropdownMenuItem.parents('.site-navigation__dropdown').length < 1) {
            dropdownMenuItem.wrap(
              '<div class="site-navigation__dropdown js-nav_dropdown" menu-sub="' +
                i +
                '"><div class="responsive-container"></div></div>'
            );
          }
        })
        .promise()
        .done(function () {
          $(window).trigger('gnav::initDropdowns');
        });
      var carousel = $module.find('.slick-initialized');

      $module
        .find('.is-dropdown')
        .once()
        .on('mouseenter', function () {
          var $thisDropdown = $(this);
          var $dropdown = $thisDropdown.find('.site-navigation__dropdown');
          var $blockimageFocus = $('.block-image-hover');

          $(document).trigger('click');
          $thisDropdown.find('.js-mainMenu_link').attr('aria-expanded', 'true');
          if ($thisDropdown.find('a[href*="omit_link"]')) {
            $thisDropdown.find('a[href*="omit_link"]').attr('href', '').click(false);
          }

          if ($thisDropdown.hasClass('is-carousel')) {
            $dropdown = $thisDropdown.find('.site-navigation__carousel');
          }
          if (
            $thisDropdown.length === 1 &&
            !$thisDropdown.hasClass('.site-navigation__more') &&
            $dropdown.length === 1
          ) {
            $(window).scroll();
            if (!$thisDropdown.hasClass('is-active')) {
              $thisDropdown.addClass('is-active');
              $dropdown
                .slideDown(0, function () {
                  $(this).css('overflow', 'visible');
                  if ($thisDropdown.hasClass('is-carousel')) {
                    carousel.slickSetOption('infinite', false, true);
                  }
                })
                .bind('mouseleave', function () {
                  $dropdown.find('.site-navigation__item').removeClass('is-active');
                  $thisDropdown.removeClass('is-active');
                  if (!Drupal.settings.globals_variables.gnav_show) {
                    $(this).hide();
                  }
                  $thisDropdown.find('.js-mainMenu_link').attr('aria-expanded', 'false');
                });
            }
            $(window).scroll(function () {
              if ($thisDropdown.hasClass('is-active')) {
                $dropdown.find('.site-navigation__item').removeClass('is-active');
                $dropdown.hide();
                $thisDropdown.removeClass('is-active');
              }
            });
            $(window).one('resize', function () {
              if ($thisDropdown.hasClass('is-active')) {
                $dropdown.slideUp(300, function () {
                  $dropdown.find('.site-navigation__item').removeClass('is-active');
                  $thisDropdown.removeClass('is-active');
                });
              }
            });
            $blockimageFocus.each(function () {
              $blockimageFocus
                .on('focusin', function () {
                  $(this).addClass('focused');
                })
                .on('keydown', function (e) {
                  if (e.shiftKey) {
                    $blockimageFocus.removeClass('focused');
                  }
                });
              $blockimageFocus.find('[role]').on('focusout', function () {
                $blockimageFocus.removeClass('focused');
              });
            });

            return false;
          }
        })
        .on('mouseleave', function () {
          $('.site-navigation__item').removeClass('is-active');
          $('.site-navigation__dropdown').hide();
          $('.site-navigation__carousel').hide();
        });
      $module
        .find('li.site-navigation__item:not(.subnav):not(.site-navigation__more)')
        .not('.is-dropdown')
        .hover(function () {
          $('.site-navigation__item').removeClass('is-active');
          $('.site-navigation__dropdown').hide();
        });
      $module.find('.site-navigation__link').click(function () {
        var href = $(this).attr('href');

        if (href == '' || href == '/') {
          return false;
        }
      });

      $module.find('.is-submenu').hover(
        function () {
          $module.find('.is-submenu').removeClass('is-active');
          $(this).addClass('is-active');
          $(this).find('.js-subMenu-link').attr('aria-expanded', 'true');
        },
        function () {
          $(this).removeClass('is-active');
          $(this).find('.js-subMenu-link').attr('aria-expanded', 'false');
        }
      );
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = $(self.get('formatter'), context);

      formatter.each(function () {
        var thisFormatter = $(this);
        var $module = thisFormatter;
        // Mobile nav
        var $mobileNav = thisFormatter.find('#mobile-nav', context);
        var $mobileBackButton = $('.mobile-checkout-back', context);
        var mobileCheckout = !!$('body.checkout, body.viewcart').length;

        if (mobileCheckout) {
          if (!$('#confirmation-page').length) {
            $mobileBackButton.appendTo('#site-header .site-header__extra');
            $mobileBackButton.removeClass('hidden');
            $mobileNav.remove();
          } else if ($('#confirmation-page').length) {
            $mobileBackButton.remove();
          }
        } else {
          $mobileNav.appendTo('#site-header .site-header__extra');
          $mobileNav.removeClass('hidden');
          $mobileBackButton.remove();
        }

        // Drop-down menus
        self.initDropdowns($module, context);
        // Mobile nav
        self.buildMobileNav($module, context);

        // More menu

        var menu = $('ul.site-navigation__menu', $module).first();
        var $subMenu = $('.mm-item-v1', menu);
        var $subCarousel = $('.site-navigation__carousel', $subMenu);
        var moreText = menu.attr('data-more-text');
        var moreMenu = $(
          '<li class="site-navigation__item site-navigation__more"><a href="#">' +
            moreText +
            ' <i class="icon--caret-down"></i></a></li>'
        ).appendTo(menu);
        var moreMenuMenu = $('<ul class="site-navigation__more-menu"></ul>').appendTo(moreMenu);
        var throttle;

        // Using $(window).load() due to a known caching issue in Chrome which prevents the code from making accurate calculations
        $(window).load(function () {
          $(window)
            .resize(
              _.debounce(function () {
                self.buildMoreMenu($module, menu, moreMenu, moreMenuMenu);
              }, 50)
            )
            .resize();

          // Adds tabindex for content in MAC Select FAQ
          $('.mac-faq__q').attr({
            tabindex: '0',
            'aria-expanded': 'false'
          });
        });
        moreMenu
          .find('> a')
          .once()
          .click(function () {
            if (!moreMenu.hasClass('active')) {
              moreMenu.addClass('active');
              menu.css('overflow', 'visible');
              moreMenuMenu.slideDown(300);
            } else {
              moreMenu.removeClass('active');
              moreMenuMenu.slideUp(300);
            }

            return false;
          });
        $('html, body').click(function () {
          moreMenu.removeClass('active');
          moreMenuMenu.slideUp(300);
        });
        moreMenuMenu.on('mouseleave', function () {
          moreMenu.removeClass('active');
          $(this).stop().slideUp(300);
        });
      });
    }
  };
})(jQuery, Drupal);
